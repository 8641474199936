import { API } from "../../api";

export type UpdatePasswordParams = {
  id: string;
  user_id: string;
};

export type UpdatePasswordBody = {
  pass_name_people: string;
  pass_name_people_presentation: string;
  pulling_horse: string;
  steer_horse: string;
  steerer: string;
  representation: string;
};

export type UpdatePasswordInput = Partial<UpdatePasswordParams> &
  Partial<UpdatePasswordBody>;

export async function updatePassword(input: UpdatePasswordInput) {
  const {
    pass_name_people,
    pass_name_people_presentation,
    pulling_horse,
    steer_horse,
    steerer,
    representation,
    id,
    user_id,
  } = input;

  const body: UpdatePasswordBody = {
    pass_name_people: pass_name_people as string,
    pass_name_people_presentation: pass_name_people_presentation as string,
    pulling_horse: pulling_horse as string,
    steer_horse: steer_horse as string,
    steerer: steerer as string,
    representation: representation as string,
  };
  const { data } = await API.patch(
    `/password/${input.id}/users/${input.user_id}`,
    body
  );
  return data;
}
