/* eslint-disable react-hooks/exhaustive-deps */
import { CheckoutStyled } from "./styled";

import { FaTicketAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatSelectDay } from "../../utils/format-select-day";
import { getItem } from "../../services/storage/storage";
import { getOrder } from "../../services/http/payment/get-order";
import { createMultiplePassword } from "../../services/http/passwords/get-all-my";
import { io } from "socket.io-client";

type PaymentStatus = {
  orderId: string;
  status: string;
};

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [verifyingPayment, setVerifyingPayment] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
    null
  );

  const paymentData: any = getItem("@pix-payment-response");
  const passwordData: any = getItem("@password-data");

  const socket = io("wss://msenhas-api-production-ca65.up.railway.app", {
    transports: ["websocket"],
  });

  socket.on("connect", () => console.log("✅ Conectado ao WebSocket!"));
  socket.on("connect_error", (err) =>
    console.error("❌ Erro na conexão:", err)
  );
  socket.on("paymentStatusUpdate", (data) => {
    console.log("🔔 Atualização de pagamento:", data);
    setPaymentStatus(data);
  });

  function handleCopy() {
    const pixCode = paymentData.charges[0].last_transaction.qr_code;
    navigator.clipboard
      .writeText(pixCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Erro ao copiar o código PIX:", err);
      });
  }

  function formattedValue(value: number) {
    return (value / 100).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  async function handleCheckPayment() {
    setVerifyingPayment(true);
    try {
      await getOrder(`${paymentData.id}`);
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setVerifyingPayment(false);
      }, 3000);
    }
  }

  useEffect(() => {
    handleCheckPayment();
  }, []);

  useEffect(() => {
    if (paymentStatus?.orderId === paymentData.id) {
      if (paymentStatus?.status === "paid") {
        navigate(`/payment-status/${paymentStatus?.status}`);
      } else {
        navigate(`/payment-status/${paymentStatus?.status}`);
      }
    }
  }, [paymentStatus]);

  return (
    <>
      {paymentData && (
        <CheckoutStyled>
          <div className="boxed-informations">
            <div className="boxedPaiment">
              <div className="w-full max-w-[260px] flex flex-col items-center border border-gray-300 rounded-md p-2">
                <div className="w-[220px]">
                  <img
                    src={paymentData.charges[0].last_transaction.qr_code_url}
                    alt="qr_code"
                    width={240}
                  />
                </div>
                <div className="w-[94%]">
                  <p className="text-slate-700">Pix copia e cola:</p>
                  <div className="flex items-center gap-x-2 mt-1 px-2 py-1 border rounded-md border-gray-300">
                    <span className="w-[190px] truncate block text-sm text-slate-700">
                      {paymentData.charges[0].last_transaction.qr_code}
                    </span>
                  </div>
                </div>
                <div className="w-[94%] mt-3">
                  <button
                    className="w-full bg-[#00DF81] text-white px-2 py-2 rounded-md transition-all duration-300 ease-in-out"
                    disabled={verifyingPayment}
                    onClick={handleCopy}
                  >
                    {copied ? "Copiado" : "Copiar"}
                  </button>
                </div>
                <div className="mt-2">
                  ⏳{" "}
                  <span className="text-left text-black">
                    Expira em: <strong>30 minutos</strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="px-4 lg-custom:mt-0">
              <h2 className="text-lg font-medium text-slate-700">
                Informações da compra
              </h2>
              <div className="flex mt-4">
                <div className="flex items-center gap-x-2">
                  <p className="text-slate-700">Valor:</p>
                  <div className="flex items-center gap-x-2 bg-[#0082BA] p-2 rounded-md">
                    <span className="text-sm">
                      {formattedValue(paymentData.amount)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:items-center gap-2 mt-4">
                <p className="text-slate-700">Senhas:</p>
                <div className="flex flex-wrap items-center gap-2">
                  {passwordData?.passwords &&
                    passwordData.passwords.map((item: any) => (
                      <div className="flex items-center gap-x-2 bg-[#00DF81] p-2 rounded-md">
                        <FaTicketAlt />
                        <span className="text-sm">
                          {item.number} - {item.category.categ_name} -{" "}
                          {formatSelectDay(item.day.day_index)}
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="mt-4">
                <h2 className="text-lg font-medium text-slate-700">
                  Informações do comprador
                </h2>
                <div className="mt-4">
                  <ul className="flex flex-col gap-y-2 text-slate-700">
                    <li>
                      Nome: <strong>{paymentData.customer.name}</strong>
                    </li>
                    <li>
                      E-mail: <strong>{paymentData.customer.email}</strong>
                    </li>
                    <li>
                      Telefone:{" "}
                      <strong>{`(${
                        paymentData.customer.phones.home_phone.area_code
                      }) ${paymentData.customer.phones.home_phone.number.slice(
                        0,
                        5
                      )}-${paymentData.customer.phones.home_phone.number.slice(
                        5
                      )}`}</strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-4">
                <h2 className="text-lg font-medium text-slate-700">
                  Informações adicionais
                </h2>
                <div className="mt-2">
                  <span className="text-slate-700">
                    Esta tela será atualizada automaticamente quando o pagamento
                    for confirmado.
                  </span>
                </div>
              </div>
              {/* <ul className="observations">
                  <li>FIQUE ATENTO! AS SENHAS NÃO PAGAS ATÉ O VENCIMENTO DO BOLETO SÃO LIBERADAS PARA COMPRA NOVAMENTE.</li>
                  <li>FIQUE ATENTO! AS SENHAS INICIALIZADAS COM CARTÃO DE CRÉDITO, PIX OU PIXEXPRESS QUE NÃO TIVERAM O PAGAMENTO CONFIRMADO SERÃO LIBERADAS PARA COMPRA NOVAMENTE.</li>
                  <li>Você pode realizar o pagamento atravéz de PIX R$ 500,00 + TAXA ADMINISTRATIVA de R$ 3,60.</li>
                  <li>Você pode realizar o pagamento atravéz de PIXEXPRESS R$ 500,00 + TAXA ADMINISTRATIVA de R$ 3,50.</li>
                  <li>Você pode realizar o pagamento atravéz de boleto R$ 500,00 + TAXA ADMINISTRATIVA de R$ 4,70.</li>
                  <li>Se preferir você pode realizar seu pagamento com cartão de crédito no valor de R$ 500,00 + TAXA ADMINISTRATIVA de 7.5% e dividir em até 12x com juros</li>
                  <li>FIQUE ATENTO! EM CASO DE DEVOLUÇÃO, É DEVOLVIDO O VALOR DA SENHA, OS ACRÉSCIMOS SÃO COBRADOS PELO BANCO E O SITE NÃO CONSEGUE DEVOLVER.</li>
                </ul> */}
            </div>
          </div>
        </CheckoutStyled>
      )}
      <div className="h-6" />
    </>
  );
};

export default CheckoutPage;
