import { CreateMultiplePasswordType } from "../../../types/password";
import { API } from "../../api";

export async function getPasswordsByCpf(cpf: string) {
  const { data } = await API.post("/password/filter", { cpf });
  return data;
}

export async function getAllMy() {
  const { data } = await API.get("/password/all/my");
  return data;
}

export async function createMultiplePassword(body: CreateMultiplePasswordType) {
  const { data } = await API.post(`/password/create/multiple`, body as any);
  return data;
}
