import { ControllerStyled, HomeListStyled } from "./styled";

// COMPONENTS
// import Card from "../../components/cards/card";
import Card from "../../components/Card/card";
import { useEffect, useState } from "react";

// SERVICES
import { getAllPagination, getAllFilter } from "./home.services";
import { PosterAllType } from "../../types/poster";
import { useLoading } from "../../services/contexts/LoadingContext";
import { useMessage } from "../../services/contexts/MessageContext";
import { StatesData } from "../../configs/data";
import { NotExistParagraph } from "../../assets/css/notExist";
import { CiCircleAlert } from "react-icons/ci";
import { Pagination, PaginationItem } from "../../assets/css/pagination";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Spinner from "../../components/Spinner/spinner";
import { isExpired } from "../../utils/is-expired";
import { decrypt, encrypt } from "../../services/encrypt/encrypt";
import { FaChevronDown, FaWhatsapp } from "react-icons/fa";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

type AllDataPaginationType = {
  pages: number;
  current: number;
  rows: Array<PosterAllType>;
};

const quantPage = 20;

const HomePage = () => {
  const { addMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState<AllDataPaginationType>({
    current: 0,
    pages: 0,
    rows: [],
  });

  const [expiredData, setExpiredData] = useState<PosterAllType[]>([]);

  async function getAllPosters() {
    setLoading(true);
    try {
      const allVaquejadas: AllDataPaginationType = await getAllPagination(
        1,
        quantPage
      );

      // const filteredData = allVaquejadas.rows.filter(item => !isExpired(item.post_date));
      // const expiredItems = allVaquejadas.rows.filter(item => isExpired(item.post_date));

      // console.log(filteredData);

      // setAllData(prev => ({
      //   ...prev,
      //   rows: filteredData,
      // }));

      setAllData(allVaquejadas);

      // setExpiredData(expiredItems);
    } catch (err) {
      console.log(err);
      return addMessage("Erro ao buscar vaquejadas", "error");
    } finally {
      setLoading(false);
    }
  }

  const pagination = async (page: number) => {
    try {
      const allVaquejadas: AllDataPaginationType = await getAllPagination(
        page,
        quantPage
      );
      setAllData(allVaquejadas);
      setLoading(false);
    } catch (err) {
      console.log(err);
      return addMessage("Erro ao buscar vaquejadas", "error");
    }
  };

  const filter = async (value: string) => {
    setLoading(true);
    if (value === "all") {
      const allVaquejadas: AllDataPaginationType = await getAllPagination(
        1,
        quantPage
      );
      setAllData(allVaquejadas);
    } else {
      const allVaquejadas: AllDataPaginationType = await getAllFilter(
        {
          state: value,
        },
        1,
        quantPage
      );
      setAllData(allVaquejadas);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPosters();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <h2 className="text-blue-primary font-semibold">VAQUEJADAS RECENTES</h2>
        <div className="flex flex-col">
          {/* <label className="text-slate-600 font-medium mb-2">Categoria</label> */}
          <div className="flex justify-center relative min-w-[220px] h-[45px]">
            <select
              className="w-full appearance-none pr-[30px] pl-[15px] bg-transparent rounded-md border focus:outline-none text-blue-primary border-slate-200 z-10"
              onChange={(e) => filter(e.target.value)}
            >
              <option className="text-black" value="all">
                Todos
              </option>
              {StatesData.map((item: any, index: any) => (
                <option className="text-black" value={item.tag} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <div className="absolute flex justify-center items-center w-[50px] h-full right-0">
              <FaChevronDown className="text-blue-primary" />
            </div>
          </div>
        </div>
        {/* <div className="px-3 py-2 rounded-md border">
          <select className="text-blue-primary font-medium focus:outline-none bg-red" onChange={e => filter(e.target.value)}>
            <option className="text-black" value="all">Todos</option>
            {
              StatesData.map((item, index) => (
                <option
                  className="text-black"
                  value={item.tag}
                  key={index}
                >
                  {item.name}
                </option>
              ))
            }
          </select>
        </div> */}
      </div>

      {loading ? (
        <div className="mt-6">
          <Spinner />
        </div>
      ) : (
        <>
          {allData.rows.length > 0 ? (
            <div className="w-full flex justify-center items-center mt-6">
              <div
                className={`w-full max-w-7xl flex flex-wrap ${
                  allData.rows.length < 3 ? "justify-start" : "justify-center"
                }  items-center gap-4`}
              >
                {/* <Splide
                aria-label="My Favorite Images"
                options={{
                  type: 'loop',
                  autoWidth: true,
                  gap: '2rem',
                  pagination: false,
                  perMove: 1,
                  arrows: false,
                  autoScroll: {
                    speed: 1,
                  },
                }}
                extensions={{ AutoScroll }}
              > */}
                {allData.rows.slice(0, 3).map((item, index) => (
                  <Card
                    key={index}
                    expired={isExpired(item.post_date)}
                    {...item}
                  />
                ))}
                {/* </Splide> */}
              </div>
            </div>
          ) : (
            <NotExistParagraph>
              <CiCircleAlert />
              Nenhuma vaquejada encontrada
            </NotExistParagraph>
          )}
          {allData.rows.length > 0 && allData.pages > 1 && (
            <Pagination style={{ marginBottom: "3em" }}>
              <p></p>
              <ul>
                <PaginationItem active={allData.current > 1}>
                  <IoIosArrowBack />
                </PaginationItem>
                {Array(allData.pages)
                  .fill("")
                  .map((_, index) => (
                    <PaginationItem
                      onClick={() => pagination(index + 1)}
                      active={allData.current === index + 1}
                      key={index}
                    >
                      <p>{index + 1}</p>
                    </PaginationItem>
                  ))}
                <PaginationItem active={allData.current < allData.pages}>
                  <IoIosArrowForward />
                </PaginationItem>
              </ul>
              <p></p>
            </Pagination>
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
