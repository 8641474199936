import logo from "../../assets/images/logo-msenhas-ofcl.png";

export default function PoliticaDeCancelamentoEReembolsoPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full lg:h-screen bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center mt-6 mb-6">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          POLÍTICA DE CANCELAMENTO E REEMBOLSO
        </h1>
        <div className="mt-4 px-12">
          <p>
            A MSENHAS TECNOLOGIA LTDA valoriza a transparência e a satisfação de
            seus clientes. Esta política visa esclarecer as condições para
            cancelamento e reembolso de produtos e/ou serviços adquiridos
            através de nossos canais oficiais.
          </p>
          <div className="py-2" />
          <strong className="text-lg">Política de Cancelamento</strong>
          <p className="mt-2">
            1. O cliente pode solicitar o cancelamento de qualquer serviço ou
            produto oferecido em nosso site ou plataforma oficial, desde que a
            solicitação seja enviada dentro do prazo especificado no momento da
            compra.
          </p>
          <p className="mt-2">
            2. A MSENHAS TECNOLOGIA LTDA realizará o estorno total do valor
            pago, exceto taxas administrativas e juros cobrados pelas operadoras
            de cartão de crédito ou outros meios de pagamento utilizados.
          </p>
          <p className="mt-2">
            3. Cancelamentos solicitados após o prazo informado não serão
            elegíveis para reembolso, salvo em casos excepcionais devidamente
            justificados e analisados pela empresa.
          </p>
          <div className="py-2" />
          <strong className="text-lg">Política de Reembolso</strong>
          <p className="mt-2">
            1. Taxas administrativas e juros de cartão de crédito não são
            reembolsáveis
          </p>
          <p className="mt-2">
            2. O prazo para a solicitação é até às 20 horas do último dia das
            vendas pelo site, pois ao encerramento das vendas os valores são
            repassados para o organizado da vaquejada que você comprou a senha.
          </p>
          <p className="mt-2">
            3. O reembolso será processado dentro de até 24 horas após a
            aprovação da solicitação, podendo o prazo para efetivação depender
            das políticas da instituição financeira responsável pelo meio de
            pagamento utilizado.
          </p>
          <p className="mt-2">
            4. Pagamentos realizados via boleto bancário serão reembolsados
            exclusivamente na conta bancária vinculada ao CPF/CNPJ do titular do
            cadastro, sendo necessário o envio dos dados bancários completos no
            momento da solicitação.
          </p>
          <p className="mt-2">
            5. Para pagamentos efetuados por cartão de crédito, o valor poderá
            não ser creditado no mesmo mês, dependendo do fechamento da fatura
            do cliente.
          </p>
          <p className="mt-2">
            6. Assim que a devolução do valor for confirmada, os serviços ou
            produtos vinculados à compra serão automaticamente cancelados.
          </p>
          <p className="mt-2">
            A MSENHAS TECNOLOGIA LTDA se reserva o direito de revisar e
            atualizar esta política a qualquer momento, garantindo a
            conformidade com a legislação vigente e os melhores padrões de
            atendimento ao consumidor.
          </p>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100"></div>
    </div>
  );
}
