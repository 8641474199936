import { useEffect, useState } from "react";

import { Title } from "../../assets/css/title";
import { MyPasswordsStyled } from "./styled";
import {
  Pagination,
  PaginationInfo,
  PaginationItem,
} from "../../assets/css/pagination";

// ICONS
import { CiUser } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoTicketOutline } from "react-icons/io5";
import { MdEdit, MdOutlineDocumentScanner } from "react-icons/md";
import { allPasswordsType } from "../../types/password";
import { IoMdDownload } from "react-icons/io";
import { useMessage } from "../../services/contexts/MessageContext";
import { getAllMy } from "../../services/http/passwords/get-all-my";
import { updatePassword } from "../../services/http/passwords/update-password";
import { PDFDownloadLink } from "@react-pdf/renderer";

import PDFComprovante from "./pdf";
import { DateData } from "../../configs/data";
import { formatDate } from "../../utils/format-date";
import Modal from "../../components/Modals/modal";
import Spinner from "../../components/Spinner/spinner";

const MyPasswordsPage = () => {
  const { addMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [myPasswords, setMyPasswords] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedPass, setSelectedPass] = useState<any | null>(null);
  const [form, setForm] = useState({
    pass_name_people: "",
    pass_name_people_presentation: "",
    pulling_horse: "",
    steer_horse: "",
    steerer: "",
    representation: "",
  });

  function handleChange(event: any) {
    const inputName = event.target.name;
    let inputvalue = event.target.value;

    setForm({ ...form, [inputName]: inputvalue });
  }

  async function getAllMyPasswords() {
    setLoadingPage(true);
    try {
      const response = await getAllMy();
      setMyPasswords(response);
      return;
    } catch (e) {
      addMessage("Erro ao buscar senhas", "error");
      console.log(e);
    } finally {
      setLoadingPage(false);
    }
  }

  async function handleUpdatePass() {
    setLoading(true);
    try {
      await updatePassword({
        id: selectedPass.pass_id,
        user_id: selectedPass.user_id,
        pass_name_people: form.pass_name_people,
        pass_name_people_presentation: form.pass_name_people_presentation,
        pulling_horse: form.pulling_horse,
        steer_horse: form.steer_horse,
        steerer: form.steerer,
        representation: form.representation,
      });
      setOpen(false);
      getAllMyPasswords();
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllMyPasswords();
  }, []);

  useEffect(() => {
    if (myPasswords && selectedPass) {
      setForm({
        pass_name_people: selectedPass.pass_name_people,
        pass_name_people_presentation:
          selectedPass.pass_name_people_presentation,
        pulling_horse: selectedPass.pulling_horse,
        steer_horse: selectedPass.steer_horse,
        steerer: selectedPass.steerer,
        representation: selectedPass.representation,
      });
    }
  }, [selectedPass]);

  return (
    <MyPasswordsStyled>
      {/* <Title>Minhas senhas</Title> */}
      {loadingPage ? (
        <div className="mb-8">
          <Spinner />
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <div>NOME</div>
                </th>
                <th>
                  <div>CPF</div>
                </th>
                <th>
                  <div>NÚMERO</div>
                </th>
                <th>
                  <div>CATEGORIA</div>
                </th>
                <th>
                  <div>DIA</div>
                </th>
                <th>
                  <div>BOI NA TV</div>
                </th>
                <th>
                  <div>VAQUEJADA</div>
                </th>
                <th>
                  <div>DATA</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {myPasswords &&
                myPasswords.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div>
                        <CiUser />
                        {item.pass_name_people}
                      </div>
                    </td>
                    <td>
                      <div>
                        <MdOutlineDocumentScanner />
                        {item.pass_cpf_people}
                      </div>
                    </td>
                    <td>
                      <div>
                        <IoTicketOutline /> {item.pass_number}
                      </div>
                    </td>
                    <td>
                      <div>Categ. {item.category.categ_name}</div>
                    </td>
                    <td>
                      <div>
                        {
                          DateData.filter(
                            (day) => day.tag === item.day.day_index
                          )[0].name
                        }
                      </div>
                    </td>
                    <td>
                      <div>{item.boi_on_tv ? "Sim" : "Não"}</div>
                    </td>
                    <td>
                      <div>{item.poster.post_name}</div>
                    </td>
                    <td>
                      <div>{formatDate(item.poster.post_date)}</div>
                    </td>
                    <td>
                      <div className="flex justify-center items-center">
                        <button
                          onClick={() => {
                            setSelectedPass(item);
                            setOpen(true);
                          }}
                          className="w-5"
                        >
                          <MdEdit />
                        </button>
                      </div>
                    </td>
                    {/* <td>
                    <div style={{ cursor: "pointer" }}>
                      <PDFDownloadLink
                        document={<PDFComprovante datas={item} />}
                        fileName="Comprovante de compra.pdf"
                      >
                        {({ blob, url, loading, error }: any) =>
                          loading ? "Carregando documento..." : <IoMdDownload />
                        }
                      </PDFDownloadLink>
                    </div>
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedPass(null);
        }}
      >
        <div className="w-full max-w-[880px] px-6 py-4">
          <h2 className="text-xl text-slate-800 font-medium">Editar senha</h2>

          <div className="flex flex-col gap-y-2 w-[320px] md:w-[740px] mt-4">
            <div className="flex flex-col md:flex-row items-center gap-2">
              <div className="w-full flex flex-col gap-y-1">
                <p className="text-slate-800">Nome completo do vaqueiro</p>
                <input
                  value={form.pass_name_people}
                  onChange={handleChange}
                  name="pass_name_people"
                  type="text"
                  placeholder="Nome do vaqueiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
              </div>
              <div className="w-full flex flex-col gap-y-1">
                <p className="text-slate-800">Nome chamado pela locução</p>
                <input
                  value={form.pass_name_people_presentation}
                  onChange={handleChange}
                  name="pass_name_people_presentation"
                  type="text"
                  placeholder="Apelido do vaqueiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-y-1">
              <p className="text-slate-800">Cavalo puxador</p>
              <input
                value={form.pulling_horse}
                onChange={handleChange}
                name="pulling_horse"
                type="text"
                placeholder="Nome do cavalo puxador"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
            </div>
            <div className="w-full flex flex-col gap-y-1">
              <p className="text-slate-800">Esteireiro</p>
              <input
                value={form.steerer}
                onChange={handleChange}
                name="steerer"
                type="text"
                placeholder="Nome do esteireiro"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
            </div>
            <div className="w-full flex flex-col gap-y-1">
              <p className="text-slate-800">Cavalo esteireiro</p>
              <input
                value={form.steer_horse}
                onChange={handleChange}
                name="steer_horse"
                type="text"
                placeholder="Nome do cavalo esteireiro"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
            </div>
            <div className="w-full flex flex-col gap-y-1">
              <p className="text-slate-800">Representação</p>
              <input
                value={form.representation}
                onChange={handleChange}
                name="representation"
                type="text"
                placeholder="Digite sua representação"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
            </div>
            <div className="flex justify-end items-center gap-x-2 mt-4">
              <button
                onClick={handleUpdatePass}
                className={`px-4 py-1.5 rounded-md bg-blue-primary ${
                  loading && "opacity-75"
                }`}
                disabled={loading}
              >
                {loading ? "Salvando" : "Salvar"}
              </button>
              <button
                onClick={() => setOpen(false)}
                className="border px-3.5 py-[5px] rounded-md border-gray-500 text-slate-800"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </MyPasswordsStyled>
  );
};

export default MyPasswordsPage;
