import styled from "styled-components";

export const ProfileStyled = styled("ul")`
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 6px;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    padding: 0.4em 1em;
    display: flex;
    align-items: center;
    .logo {
      height: 45px;
    }
    .people {
      position: relative;
      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 100px;
        cursor: pointer;
      }
      ul {
        position: absolute;
        background-color: ${({ theme }) => theme.background.secondary};
        right: -16px;
        top: calc(100% + 10px);
        /* border: solid 1px ${({ theme }) => theme.border.opacity}; */
        border-radius: 6px;
        width: 200px;
        padding: 0px;
        margin: 0px;
        li {
          color: red;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          margin: 0px;
          border-radius: 10px;
          font-weight: 600;
          &:hover {
            background-color: ${({ theme }) => theme.background.secondary2};
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    width: 85%;
  }

  @media (max-width: 1300px) {
    width: 95%;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1em;
`;
